/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
copyToClipboard = function (text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        if (window.clipboardData.setData("Text", text)) {
            toastr.success('', _translations.copy_license_key_success);
        }
        else {
            toastr.error('', _translations.copy_license_key_error);
        }
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            if (document.execCommand("copy")) {
                toastr.success('', _translations.copy_license_key_success);
            }
            else {
                toastr.error('', _translations.copy_license_key_error);
            }
        }
        catch (ex) {
            toastr.error('', _translations.copy_license_key_error);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}